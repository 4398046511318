@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.css-18tus3s {
  text-wrap: wrap;
  max-width: 88px !important;
  overflow-wrap: break-word !important;
  text-overflow: clip;
  /* /* overflow-x: hidden; */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* #master_flex_header_navbar{
  position: absolute;
  z-index: 999999;
} */

#headernavbar {
  z-index: 999999;
}

/* #performance_dashboard_box{
  position: absolute;
  z-index: -9999;
  padding: 0px;
  padding-right: 10px;
  width: calc(100% - 30px);
  top: 90px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1c2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(116, 116, 116);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left, .apexcharts-legend.apx-legend-position-top.apexcharts-align-left, .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
  justify-content: flex-start;
  align-items: self-start;
} */

/* .apexcharts-legend{

} */

.apexcharts-tooltip {
  color: #ffffff !important;
}
.custom-date-picker {
  /* Add your custom styles here */
  /* background-color: #f0f0f0; */
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 400px;
  /* Add more styles as needed */
}
